/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pt-4>
    <v-flex xs12 lg6>
      <v-alert
        dense
        outlined
        color="green"
        colored-border
        rounded="lg"
        class="app-alert"
      >
        <span class="title-3">
          {{ $t("pages.settings.noti_desc") }}
        </span>
      </v-alert>
    </v-flex>
    <v-flex xs12 pt-8>
      <v-layout wrap justify-start>
        <v-flex xs12 lg6>
          <v-checkbox
            v-model="email"
            :label="$t('form.email_noti')"
            color="primary"
            :ripple="false"
            hide-details
          />
        </v-flex>
      </v-layout>
      <v-flex xs12 sm6 pt-6> <v-divider /> </v-flex>
      <v-layout wrap justify-start>
        <v-flex xs12 lg6>
          <v-checkbox
            v-model="sms"
            :label="$t('form.sms_noti')"
            color="primary"
            :ripple="false"
            hide-details
          />
        </v-flex>
      </v-layout>
      <v-flex xs12 sm6 pt-6> <v-divider /> </v-flex>
      <v-layout wrap justify-start>
        <v-flex xs12 lg6>
          <v-checkbox
            v-model="sms"
            :label="$t('form.whatsapp_noti')"
            color="primary"
            :ripple="false"
            hide-details
          />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Notification",
  data: () => ({
    email: false,
    sms: false,
  }),
  mounted() {
    const breadCrumbs = [
      {
        name: "settings",
        text: "pages.settings.title",
      },
      {
        name: this.$route.name,
        text: "pages.settings.notification",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
};
</script>

<style scoped>
.v-alert {
  background-color: #fafbff !important;
  border-color: #cfd8e9 !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>

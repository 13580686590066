var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "pt-4": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", lg6: "" } },
        [
          _c(
            "v-alert",
            {
              staticClass: "app-alert",
              attrs: {
                dense: "",
                outlined: "",
                color: "green",
                "colored-border": "",
                rounded: "lg",
              },
            },
            [
              _c("span", { staticClass: "title-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("pages.settings.noti_desc")) + " "),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", "pt-8": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", lg6: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: _vm.$t("form.email_noti"),
                      color: "primary",
                      ripple: false,
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pt-6": "" } },
            [_c("v-divider")],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", lg6: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: _vm.$t("form.sms_noti"),
                      color: "primary",
                      ripple: false,
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.sms,
                      callback: function ($$v) {
                        _vm.sms = $$v
                      },
                      expression: "sms",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "", "pt-6": "" } },
            [_c("v-divider")],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", lg6: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: _vm.$t("form.whatsapp_noti"),
                      color: "primary",
                      ripple: false,
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.sms,
                      callback: function ($$v) {
                        _vm.sms = $$v
                      },
                      expression: "sms",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }